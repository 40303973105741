import React from "react"
import styled from "styled-components"
import SaleBg from "../../images/sale/summer-sale.jpg"
import TopLeftImage from "../../images/sale/left-top.png"
import HeadingImage from "../../images/sale/heading.png"
import TopRightImage from "../../images/sale/right-top.png"
import BuildingImage from "../../images/sale/building.png"
import OfferImage from "../../images/sale/offer.png"
import PriceTag from "../../images/sale/price-tag.png"
import CallBkg from "../../images/sale/call-bkg.png"
import PhoneImage from "../../images/sale/phone.png"
import RequestForm from "../../components/RequestForm"

const SaleBanner = styled.section`
  overflow: hidden;
  background-image: ${props => props.bg};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  max-width: 100%;
  width: 100%;
  position: relative;
  margin-top: 60px;
  padding: 30px 20px 15px 20px;
  @media (min-width: 1200px) {
    padding: 30px 0 80px 0;
    margin-top: 110px;
  }
`
const TopLeftImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 70px;
  width: 100%;
  @media (min-width: 768px) {
    max-width: 100px;
  }
  @media (min-width: 992px) {
    max-width: 120px;
  }
  @media (min-width: 1200px) {
    max-width: 130px;
  }
  @media (min-width: 1600px) {
    max-width: 170px;
  }
`
const TopRightImg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  max-width: 80px;
  width: 100%;
  @media (min-width: 768px) {
    max-width: 110px;
  }
  @media (min-width: 992px) {
    max-width: 120px;
  }
  @media (min-width: 1200px) {
    max-width: 140px;
  }
  @media (min-width: 1600px) {
    max-width: 166px;
  }
`
const SectionPanel = styled.div`
  position: relative;
`
const SectionHeading = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 300px;
  width: 100%;
  @media (min-width: 768px) {
    max-width: 400px;
  }
  @media (min-width: 992px) {
    max-width: 450px;
  }
  @media (min-width: 1200px) {
    max-width: 500px;
  }
  @media (min-width: 1400px) {
    max-width: 550px;
  }
  @media (min-width: 1600px) {
    max-width: 600px;
  }
`
const SectionBody = styled.div`
  margin: 0;
`
const GridPanel = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column-reverse;
  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`
const GridLeftPanel = styled.div`
  position: relative;
`
const GridRightPanel = styled.div`
  position: relative;
  padding: 0 20px;
`
const BuildingWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  @media (min-width: 576px) {
    margin-top: -20px;
  }
`
const BuildingImg = styled.div`
  display: flex;
`
const SalePrice = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  margin: -20px auto 0 auto;
  max-width: 220px;
  @media (min-width: 576px) {
    max-width: 350px;
  }
  @media (min-width: 768px) {
    max-width: 400px;
    margin: -40px auto 0 auto;
  }
  @media (min-width: 992px) {
    max-width: 450px;
    margin: -40px auto 0 auto;
  }
  @media (min-width: 1200px) {
    max-width: 500px;
    margin: -60px auto 0 auto;
  }
  @media (min-width: 1400px) {
    max-width: 600px;
  }
  @media (min-width: 1600px) {
    max-width: 620px;
  }
`
const OfferImg = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto 30px auto;

  max-width: 250px;
  width: 100%;
  @media (min-width: 768px) {
    max-width: 275px;
  }
  @media (min-width: 992px) {
    max-width: 300px;
  }
  @media (min-width: 1200px) {
    margin: 0 0 35px;
    max-width: 320px;
  }
  @media (min-width: 1400px) {
    max-width: 325px;
  }
`
const HelpDeskText = styled.div`
  font-family: "Roboto", serif;
  font-size: 14px;
  line-height: 20px;
  color: #666;
  text-align: center;
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
`
const HelpMobText = styled.div`
  font-family: "Roboto", serif;
  font-size: 10px;
  line-height: 18px;
  color: #666;
  text-align: center;
  margin-top: 15px;
  display: block;
  @media (min-width: 992px) {
    display: none;
  }
`

const CallCta = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto 20px auto;

  max-width: 220px;
  width: 100%;
  @media (min-width: 768px) {
    max-width: 240px;
  }
  @media (min-width: 992px) {
    max-width: 260px;
  }
  @media (min-width: 1200px) {
    max-width: 280px;
    margin: 0 auto 30px auto;
  }
  @media (min-width: 1400px) {
    max-width: 300px;
  }
  @media (min-width: 1600px) {
    max-width: 350px;
    margin: 0 auto 40px auto;
  }

  > a {
    display: inline-flex;
    position: relative;
  }
  .btn-text {
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    color: #fff;

    top: 22px;
    @media (min-width: 768px) {
      top: 24px;
    }
    @media (min-width: 992px) {
      top: 18px;
    }
    @media (min-width: 1200px) {
      top: 28px;
    }
    @media (min-width: 1400px) {
      top: 30px;
    }
    @media (min-width: 1600px) {
      top: 34px;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 18px;
      height: 24px;
      @media (min-width: 1200px) {
        width: 26px;
        height: 26px;
      }
      @media (min-width: 1400px) {
        width: 30px;
        height: 30px;
      }
      @media (min-width: 1600px) {
        width: 36px;
        height: 36px;
      }
      + .text {
        margin-left: 10px;
      }
    }
    .text {
      font-family: "Roboto", serif;
      font-weight: 700;
      letter-spacing: 1px;

      font-size: 18px;
      line-height: 24px;
      @media (min-width: 1200px) {
        font-size: 20px;
        line-height: 26px;
      }
      @media (min-width: 1400px) {
        font-size: 24px;
        line-height: 30px;
      }
      @media (min-width: 1600px) {
        font-size: 30px;
        line-height: 36px;
      }
    }
  }
`

const CardWrap = styled.div`
  margin-top: 40px;
  @media (min-width: 1200px) {
    margin-top: -50px;
  }
`
const CardHorizontal = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  border: 1px solid #ddd;
  width: 100%;
  position: relative;
  z-index: 10;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
`
const CardBody = styled.div`
  padding: 30px;
  width: 100%;
  form {
    display: flex;
    flex-wrap: wrap;
    @media (min-width: 768px) {
      margin: 0 -20px;
    }
  }
  .form-group-bordered {
    position: relative;
    width: 100%;
    textarea.form-control {
      height: 42px;
    }
    @media (min-width: 768px) {
      padding: 0 20px;
      flex: 0 0 50%;
      max-width: 50%;
      .control-label {
        left: 20px;
      }
    }
  }
  .form-action {
    position: relative;
    width: 100%;
    @media (min-width: 768px) {
      padding: 0 20px;
    }
  }

  & .form-action {
    & .btn {
      max-width: 200px;
      width: 100%;
    }
  }
`
const CardTitle = styled.div`
  font-size: 24px;
  line-height: 32px;
  color: #020304;
  margin-bottom: 30px;
  font-weight: 400;
`
// const ButtonToolbar = styled.div`
//     display: flex;
//     align-items:center;
//     justify-content:center;
//     margin:0 -5px 20px -5px;
//     .btn-item{
//         border:none;
//         outline:none;
//         background-color:transparent;
//         padding:0 5px;
//         margin:0;
//         .btn{
//             cursor: pointer;
//             @media (max-width: 1199.98px) {
//                 white-space: nowrap;
//                 padding: 6px 12px;
//             }
//         }
//     }
// `

const OfferSection = ({ location }) => {
  return (
    <>
      <SaleBanner bg={`url(${SaleBg})`}>
        <TopLeftImg>
          <img src={TopLeftImage} alt="Premier Metal Structures" />
        </TopLeftImg>
        <TopRightImg>
          <img src={TopRightImage} alt="Premier Metal Structures" />
        </TopRightImg>
        <SectionPanel>
          <SectionHeading>
            <img src={HeadingImage} alt="Premier Metal Structures" />
          </SectionHeading>
          <SectionBody>
            <GridPanel>
              <GridLeftPanel>
                <BuildingWrap>
                  <BuildingImg>
                    <img src={BuildingImage} alt="Premier Metal Structures" />
                  </BuildingImg>
                  <SalePrice>
                    <img src={PriceTag} alt="Premier Metal Structures" />
                  </SalePrice>
                </BuildingWrap>
                <HelpMobText>*Terms & Conditions Apply</HelpMobText>
              </GridLeftPanel>
              <GridRightPanel>
                <OfferImg>
                  <img src={OfferImage} alt="Premier Metal Structures" />
                </OfferImg>
                <CallCta>
                  <a href="tel:8776005980" aria-label="phone number">
                    <img src={CallBkg} alt="Premier Metal Structures" />
                    <span className="btn-text">
                      <span className="icon">
                        <img src={PhoneImage} alt="Premier Metal Structures" />
                      </span>
                      <span className="text">(877) 600-5980</span>
                    </span>
                  </a>
                </CallCta>
                {/* <ButtonToolbar className="btn-toolbar">
                                    <button className="btn-item" type="button" onClick={showQuote}><DefaultButton icon={<QouteIcon />} text="Get a Quote" /></button>
                                    <a className="btn-item" href="tel:8776005980"><DefaultOutlineButton icon={<PhoneIcon />} text="Call Us Now" /></a>
                                </ButtonToolbar> */}
                <HelpDeskText>*Terms & Conditions Apply</HelpDeskText>
              </GridRightPanel>
            </GridPanel>
          </SectionBody>
        </SectionPanel>
      </SaleBanner>
      <CardWrap>
        <div className="container">
          <CardHorizontal className="card-form">
            <CardBody className="card-body">
              <CardTitle className="card-title h2">
                Let's Get Started. Fill out this quick form.
              </CardTitle>
              <RequestForm location={location} />
            </CardBody>
          </CardHorizontal>
        </div>
      </CardWrap>
    </>
  )
}
export default OfferSection
